<template>
	<div class="data-list list-tab-show-page">
		<template v-if="soft_procedures.is_active">
			<template v-if="loaded">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-name">
							<div class="text">{{ $t('general.name') }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1115) || $mq.between([600,1024]))" class="column-filter column-entries">
							<div class="text">{{ $t('show_user.procedures_visits') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1420)" class="column-filter column-roles">
							<div class="text">{{ $t('general.roles') }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-filter column-last-update">
							<div class="text">{{ $t('general.last_update') }}</div>
						</div>
						<div class="column-filter column-user" v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-if="entries.length">
					<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
						<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name">
								<div class="circle-status" :class="{active: entry.status == 'live'}"></div>
								<div class="name link" @click="goItem(entry.slug)">{{ entry.name }}</div>
							</div>
							<div v-if="$resize && ($mq.above(1115) || $mq.between([600,1024]))" class="column-simple-text column-entries">
								<div class="text">{{ entry.analytics.entries }}</div>
							</div>
							<div v-if="$resize && $mq.above(1420)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="entry.roles.length == 1">{{ entry.roles[0].name }}</span>
										<span v-else-if="entry.roles.length > 1">{{`${entry.roles[0].name} (+${(entry.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(role, index) in entry.roles">{{index < entry.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-simple-text column-last-update">
								<div class="text">{{ entry.updated_at | moment('DD MMM YYYY') }}</div>
							</div>
							<div v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))" class="column-avatar column-user">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}">
									<img :src="entry.responsible.avatar" v-if="entry.responsible.avatar">
									<div class="user-circle" v-else>
										<icon-user />
									</div>

									<template slot="popover">
										<div class="simple-text">{{ entry.responsible.first_name }} {{ entry.responsible.last_name }}</div>
									</template>
								</v-popover>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
				<template v-else>
					<div class="row-space-tbf row-list-item">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-empty-list">
								<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('congratulations.empty-inline', {name: userName }) }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</template>
			<loader-items-list-2 v-else/>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>


<script>
	import NotModule from '@/components/General/NotModule'
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/EditDots'
	import IconProcedures from '../../Icons/Procedures'
	import IconEditMobile from '../../Icons/Edit'
	import LoaderItemsList2 from '../../PagesLoaders/ItemsList2'
	import StarRating from 'vue-star-rating'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconProcedures,
			LoaderItemsList2,
			StarRating,
			NotModule
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		props: {
			userName: String,
			userSlug: String,
			dateRange: Object
		},
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
		},
		watch:{
			$route (to, from){
				if(this.soft_procedures.is_active) {
					this.getProceduresEntries()
				}
			}
		},
		async mounted(){
			if(this.soft_procedures.is_active) {
				await this.getProceduresEntries()
			}
		},
		methods: {
			async getProceduresEntries(){
				await axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.userSlug}/procedures`)
				.then(({data}) => {
					this.entries = data.data
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			goItem(itemSlug){
				window.open(`https://proceduri.tbf.ro/procedure/${itemSlug}`, '_blank');
			}
		}
	}
</script>