<template>
	<div class="data-list list-tab-show-page">
		<template v-if="soft_objectives.is_active">
			<template v-if="loaded">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-promise">
							<div class="text">{{ $t('general.message') }}</div>
						</div>
						<div v-if="$resize && $mq.above(500)" class="column-filter column-last-update">
							<div class="text">{{ $t('general.created_at') }}</div>
						</div>
						<div class="column-filter column-status-promise"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-if="entries.length">
					<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
						<div class="space-left"><icon-promise v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-desc column-promise">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}" v-if="!['not_set', 'no_key_result'].includes(entry.status)">
									<div class="name">{{ entry.name }}</div>
									<div class="description" v-if="entry.key_results_names != null">{{ $t('show_user.promise_contribute_to') }}: {{ entry.key_results_names }}</div>
									<template slot="popover">
										<div class="simple-text">{{ entry.name }}</div>
										<div class="reason" v-if="entry.why_not"><span>{{ $t('show-user.promise_not_reason') }}:</span> {{ entry.why_not }}</div>
									</template>
								</v-popover>
								<div class="description" v-else-if="entry.status == 'no_key_result'">{{ $t('show_user.promise_no_key_result') }}</div>
								<div class="description" v-else>{{ $t('show_user.promise_not_set') }}</div>
							</div>
							<div v-if="$resize && $mq.above(500)" class="column-simple-text column-last-update">
								<div class="text">{{ entry.created_at | moment('DD MMM YYYY') }}</div>
							</div>

							<div class="column-icon-status column-status-promise">
								<img src="/build/images/updated.svg" v-if="entry.status == 'completed'">
								<img src="/build/images/upcoming.svg" class="upcoming" v-if="['pending', 'no_key_result'].includes(entry.status)">
								<img src="/build/images/attention-square.svg" v-if="entry.status == 'not_set'">
								<img src="/build/images/failed.svg" v-if="entry.status == 'not_completed'">
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
				<template v-else>
					<div class="row-space-tbf row-list-item">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-empty-list">
								<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('congratulations.empty-inline', {name: userName }) }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</template>
			<loader-items-list-2 v-else/>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>


<script>
	import NotModule from '@/components/General/NotModule'
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/EditDots'
	import IconPromise from '../../Icons/Promise'
	import IconEditMobile from '../../Icons/Edit'
	import LoaderItemsList2 from '../../PagesLoaders/ItemsList2'
	import StarRating from 'vue-star-rating'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconPromise,
			LoaderItemsList2,
			StarRating,
			NotModule
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		props: {
			userName: String,
			userSlug: String,
			dateRange: Object
		},
		computed: {
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			}
		},
		watch:{
			$route (to, from){
				if(this.soft_objectives.is_active) {
					this.getPromisesEntries()
				}
			}
		},
		async mounted(){
			if(this.soft_objectives.is_active) {
				await this.getPromisesEntries()
			}
		},
		methods: {
			async getPromisesEntries(){
				let paramsRequest = { 
					start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
					end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD')
				}

				await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.userSlug}/promises`, {params: paramsRequest })
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>