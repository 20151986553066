<template>
	<div>
		<div class="calendar-content" v-if="loaded">
			<FullCalendar ref="fullCalendar" class="app-calendar-tbf" :options="calendarOptions" :key="calendarKey"></FullCalendar>
		</div>
		<div class="content" v-else>
			<loader-calendar v-if="data.viewToolbar" />
			<div class="placeholder-loader" style="height: 650px; width: 100%;" v-else></div>
		</div>
	</div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import roLocale from "@fullcalendar/core/locales/ro";
import enLocale from "@fullcalendar/core/locales/en-gb";
import LoaderCalendar from "../PagesLoaders/Calendar";

export default {
	components: {
		FullCalendar,
		LoaderCalendar,
	},
	props: {
		data: Object,
		viewCalendar: String,
	},
	watch: {
		$route(to, from) {
			if (from.name != to.name || from.params.id != to.params.id || from.params.slug != to.params.slug) {
				this.getUserCalendarData(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"), false);
			}
		},
	},
	computed: {
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	data() {
		return {
			loaded: false,
			calendarOptions: {},
			events: [],
			monthsLoaded: [],
			startMonth: "",
			endMonth: "",
			calendarKey: 1,
			calendarSource: 1,
		};
	},
	beforeDestroy() {
		this.$root.$off("updateEventCalendar");
		this.$root.$off("addEventCalendar");
		this.$root.$off("deleteEventCalendar");
	},
	mounted() {
		const todayDate = moment().startOf("day");
		const TODAY = todayDate.format("YYYY-MM-DD");

		var defaultinitialView = "timeGridWeek";
		if (this.viewCalendar) {
			switch (this.viewCalendar) {
				case "monthly":
					defaultinitialView = "dayGridMonth";
					break;
				case "weekly":
					defaultinitialView = "timeGridWeek";
					break;
				case "daily":
					defaultinitialView = "listWeek";
					break;
			}
		}

		this.calendarOptions = {
			plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
			headerToolbar: !this.data.viewToolbar
				? ""
				: {
						left: "prev,next today",
						center: "title",
						right: "dayGridMonth,timeGridWeek,listWeek",
				  },
			initialDate: TODAY,
			initialView: defaultinitialView,
			firstDay: 1,
			droppable: false,
			navLinks: true,
			selectable: true,
			selectMirror: false,
			locales: [esLocale, roLocale, enLocale],
			locale: "ro",
			buttonText: {
				month: "Lunar",
				week: "Sapt.",
				list: "Lista",
			},
			views: {
				dayGridMonth: { buttonText: "month" },
				timeGridWeek: { buttonText: "week" },
				listWeek: { buttonText: "list" },
			},
			height: 650,
			eventMinHeight: 42,
			slotEventOverlap: false,
			editable: false,
			dayMaxEvents: true,
			lazyFetching: false,
			eventClick: this.viewEvent,
			select: this.openAddTaskFromCalendar,
			customButtons: {
				prev: {
					click: () => {
						this.changeMonthCalendar("prev");
					},
				},
				next: {
					click: () => {
						this.changeMonthCalendar("next");
					},
				},
			},
		};

		this.$root.$on("updateEventCalendar", (data) => {
			this.updateEventClass(data);
		});
		this.$root.$on("addEventCalendar", (data) => {
			this.addEvent(data);
		});
		this.$root.$on("deleteEventCalendar", (data) => {
			this.deleteEvent(data);
		});

		this.startMonth = moment(todayDate)
			.add(-2, "M")
			.startOf("month");
		this.endMonth = moment(todayDate)
			.add(2, "M")
			.endOf("month");

		this.monthsLoaded.push(this.startMonth.format("MM-YYYY"));
		this.monthsLoaded.push(todayDate.format("MM-YYYY"));
		this.monthsLoaded.push(this.endMonth.format("MM-YYYY"));

		switch (this.data.from) {
			case "user":
				this.getUserCalendarData(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"));
				break;
			case "role":
				this.getRoleResponsibilitiesData(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"));
				break;
		}
	},
	methods: {
		changeViewApi(type) {
			var calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.changeView(type);
		},
		goToTodayApi() {
			var calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.today();
		},
		nextPeriodApi() {
			var calendarApi = this.$refs.fullCalendar.getApi();
			this.changeMonthCalendar("next");
		},
		prevPeriodApi() {
			var calendarApi = this.$refs.fullCalendar.getApi();
			this.changeMonthCalendar("prev");
		},
		getUserCalendarData(startMonth, endMonth, refresh = false) {
			let paramsQuery = {
				start_interval: startMonth,
				end_interval: endMonth,
				calendar: 1,
			};

			paramsQuery = this.queryObject(paramsQuery);

			axios
				.get(`/${this.data.slug}/logs-list`, { params: paramsQuery })
				.then(({ data }) => {
					if (refresh) {
						var calendarApi = this.$refs.fullCalendar.getApi();
						this.events.push(...data.data);
					} else {
						this.events = data.data;
					}

					let newEvents = [...this.events];

					if (refresh) {
						let eventSource = calendarApi.getEventSourceById(this.calendarSource);
						eventSource.remove();

						this.calendarSource++;

						calendarApi.refetchEvents();
						calendarApi.addEventSource({
							events: newEvents,
							id: this.calendarSource,
						});
					} else {
						this.calendarOptions.eventSources = [
							{
								events: newEvents,
								id: this.calendarSource,
							},
						];
					}
				})
				.finally(() => {
					if (!refresh) {
						this.loaded = true;
					}
				});
		},
		getRoleResponsibilitiesData(startMonth, endMonth, refresh = false, filter = false) {
			axios
				.get(`/${this.data.id}/role-responsibilities-calendar`, { params: { start_interval: startMonth, end_interval: endMonth } })
				.then(({ data }) => {
					this.events.push(...data.data);
					this.calendarOptions.eventSources = [
						{
							events: this.events,
							id: 1,
						},
					];
				})
				.finally(() => {
					if (refresh) {
						let calendarApi = this.$refs.fullCalendar.getApi();
						let eventSource = calendarApi.getEventSourceById(1);
						eventSource.refetch();
					} else {
						this.loaded = true;
					}
				});
		},
		viewEvent(arg) {
			if (["task", "task_log"].includes(arg.event.extendedProps.log_type)) {
				this.$root.$emit("open_modal", "add_task", {
					crud: "edit",
					taskEditId: arg.event.extendedProps.parent_id,
					// type: arg.event.extendedProps.log_type,
					date: moment(arg.event.start).format("YYYY-MM-DD"),
				});
			} else if (arg.event.extendedProps.log_type == "key_result") {
				this.$root.$emit("open_modal", "show_task_key_result", {
					id: arg.event.extendedProps.entity_id,
				});
			} else if (arg.event.extendedProps.log_type == "performance_reminder") {
				this.$root.$emit("open_modal", "evaluate_user", {
					title: arg.event.title,
					user: arg.event.extendedProps.user,
					frequency: arg.event.extendedProps.evaluation_frequency,
					log_type: arg.event.extendedProps.log_type,
				});
			} else if (arg.event.extendedProps.log_type == "progress_reminder") {
				this.$root.$emit("open_modal", "evaluate_user", {
					title: arg.event.title,
					log_type: arg.event.extendedProps.log_type,
					objective: arg.event.extendedProps.objective,
					key_result: arg.event.extendedProps.key_result,
				});
			} else if (["meeting", "meeting_log"].includes(arg.event.extendedProps.log_type)) {
				switch (arg.event.extendedProps.status) {
					case "ongoing":
						this.$root.$emit("open_modal", "add_meeting_view_only", {
							crud: "edit",
							meetingEditId: arg.event.extendedProps.entity_id,
							type: arg.event.extendedProps.log_type,
							date: moment(arg.event.start).format("YYYY-MM-DD"),
							right: arg.event.extendedProps.edit_right,
						});
						break;
					case "finished":
						this.$root.$emit("open_modal", "meeting_summary", {
							meetingEditId: arg.event.extendedProps.entity_id,
							type: arg.event.extendedProps.log_type,
						});
						break;
					default:
						this.$root.$emit("open_modal", "add_meeting_view_only", {
							crud: "edit",
							meetingEditId: arg.event.extendedProps.entity_id,
							type: arg.event.extendedProps.log_type,
							date: moment(arg.event.start).format("YYYY-MM-DD"),
							right: arg.event.extendedProps.edit_right,
						});
						break;
				}
			} else if (arg.event.extendedProps.log_type == "vacation_day") {
				// this.$root.$emit('open_modal', 'user_free_days', {
				// 	user_id: this.data.id,
				// 	user_name: `${this.data.first_name} ${this.data.last_name ? this.data.last_name : ''}`
				// })
				// } else if(this.data.right){
			} else {
				this.$root.$emit("open_modal", "show_responsibility", {
					from: this.data.from,
					userId: this.data.id,
					userSlug: this.data.slug,
					userRight: this.data.right,
					id: arg.event.extendedProps.entity_id,
					unique_id: arg.event.id,
					date: moment(arg.event.start).format("YYYY-MM-DD"),
				});
			}
		},
		changeMonthCalendar(direction) {
			let calendarApi = this.$refs.fullCalendar.getApi();
			if (direction == "prev") {
				calendarApi.prev();
			} else {
				calendarApi.next();
			}

			let currentDate = moment(calendarApi.view.currentStart);
			let prevDate = currentDate
				.clone()
				.add(-2, "M")
				.startOf("month");
			let nextDate = currentDate
				.clone()
				.add(2, "M")
				.endOf("month");

			if (direction == "prev") {
				if (!this.monthsLoaded.includes(prevDate.format("MM-YYYY"))) {
					this.refreshDataCalendar(prevDate.startOf("month").format("YYYY-MM-DD"), prevDate.endOf("month").format("YYYY-MM-DD"));
					this.monthsLoaded.push(prevDate.format("MM-YYYY"));
				}
			} else {
				if (!this.monthsLoaded.includes(nextDate.format("MM-YYYY"))) {
					this.refreshDataCalendar(nextDate.startOf("month").format("YYYY-MM-DD"), nextDate.endOf("month").format("YYYY-MM-DD"));
					this.monthsLoaded.push(nextDate.format("MM-YYYY"));
				}
			}
		},
		updateEventClass(data) {
			if (data.id) {
				Object.assign(
					this.events.find((el) => {
						return el.id == data.id;
					}),
					data
				);

				var calendarApi = this.$refs.fullCalendar.getApi();
				var eventSource = calendarApi.getEventSourceById(1);
				eventSource.refetch();
			}
		},
		addEvent(data) {
			var calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.addEvent(data.data, true);
		},
		deleteEvent(data) {
			var calendarApi = this.$refs.fullCalendar.getApi();
			var event = calendarApi.getEventById(data.id);
			event.remove();
		},
		refreshDataCalendar(startDate, endDate) {
			if (this.data.from == "user") {
				this.getUserCalendarData(startDate, endDate, true);
			} else {
				this.getRoleResponsibilitiesData(startDate, endDate, true);
			}
		},
		openAddTaskFromCalendar(infoClicked) {
			if (this.soft_organigram.is_active) {
				var dataParsed = {
					all_day: infoClicked.allDay,
					start: infoClicked.startStr,
					end: infoClicked.endStr,
				};
				this.$root.$emit("open_modal", "choose_activity", dataParsed, false);
				// this.$root.$emit('open_modal', 'add_meeting', dataParsed, false);
				// this.$root.$emit('open_modal', 'add_task', dataParsed, false);
			}
		},
		queryObject(paramsCall) {
			if (this.data.filtersData.sources == "personal") {
				paramsCall.assignation = this.data.filtersData.sources;
			}

			if (this.data.filtersData.types && this.data.filtersData.types.length) {
				paramsCall.log_types = this.data.filtersData.types;
			}

			if (
				this.data.filtersData.filters_dashboard &&
				(this.data.filtersData.filters_dashboard.objectives.length ||
					this.data.filtersData.filters_dashboard.key_results.length ||
					this.data.filtersData.filters_dashboard.tags.length ||
					this.data.filtersData.filters_dashboard.roles.length ||
					this.data.filtersData.filters_dashboard.projects.length)
			) {
				paramsCall.objectives_ids = this.data.filtersData.filters_dashboard.objectives.length ? this.data.filtersData.filters_dashboard.objectives : [];
				paramsCall.key_results_ids = this.data.filtersData.filters_dashboard.key_results.length ? this.data.filtersData.filters_dashboard.key_results : [];
				paramsCall.tags_ids = this.data.filtersData.filters_dashboard.tags.length ? this.data.filtersData.filters_dashboard.tags : [];
				paramsCall.roles_ids = this.data.filtersData.filters_dashboard.roles.length ? this.data.filtersData.filters_dashboard.roles : [];
				paramsCall.projects_ids = this.data.filtersData.filters_dashboard.projects.length ? this.data.filtersData.filters_dashboard.projects : [];
			}

			if (this.data.filtersData.roles) {
				paramsCall.roles_ids = this.data.filtersData.roles.length ? this.data.filtersData.roles : [];
			}

			return paramsCall;
		},
	},
};
</script>
