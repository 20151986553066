<template>
    <div>
        <template v-if="loadedTimeline">
            <div class="header-table" :class="{small: data.view == 'show_user'}">
                <div class="title" :class="{small: data.view == 'show_user'}">{{$t('timeline.title')}}</div>
                <button class="btn-tbf white timeline" @click="default_timeline_offset == timeline_offset ? '' : changeOffset(false, 'default')" v-bind:class="{disabled: default_timeline_offset == timeline_offset}" :disabled="default_timeline_offset == timeline_offset" v-if="data.view != 'show_user'">
                    <div class="text">{{$t('timeline.filter')}}</div>
                    <icon-arrow class="icon"/>
                </button>
            </div>
            <section class="timeline-bar-section">
                <div class="content-section" :class="{small: data.view == 'show_user'}">
                    <div class="timeline-bar" :class="{small: data.view == 'show_user'}">
                        <div class="prev-week" @click="moment(data_timeline[6].created_at) < moment(user.created_at) ? '' : changeOffset(+1)" v-bind:class="{disabled: moment(data_timeline[6].created_at) < moment(user.created_at) }">
                            <icon-arrow class="icon-arrow left" />
                        </div>
                        <div class="hider-left" :class="{'on-user': data.view == 'show_user'}"></div>
                        <div class="current-weeks" :style="`max-width: ${listDaysWidth ? listDaysWidth + 'px;' : 'initial' }`">
                            <div class="scroll-days" v-if="loadedCalculateDay">
                                <div class="day" v-for="(day, index) in data_timeline" :data-week="moment(day.created_at).week()" v-bind:class="{'start-week': moment(day.created_at).format('d') == 1, 'end-week': moment(day.created_at).format('d') == 0, 'on-user': data.view == 'show_user'}" :style="`flex: 0 0 ${dayWidth}px; max-width: ${dayWidth}px; width: ${dayWidth}px`">
                                    <div class="promises-status">
                                        <div class="title">{{$t('show-user.promise')}}</div>
                                        <div class="status">{{ $t('timeline_status.' + day.status) }}</div>
                                    </div>
                                    <div class="num-week">
                                        <div class="text" >
                                            {{ moment(day.created_at).format('d') == 1 ? 'Sapt. '+ moment(day.created_at).week() : '' }}
                                        </div>
                                    </div>
                                    <v-popover offset="0" trigger="hover" placement="top" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 0, hide: 0}" :disabled="day.status == 'not_exist'" :class="{'on-user-popover': data.view == 'show_user'}">
                                        <div class="space-box-day" @click="day.status == 'pending' ? showModal('evaluate_promise', { promise: day}) : ''">
                                            <div class="box-day"

                                            v-bind:class="{
                                                completed: day.status == 'completed', 
                                                not_completed: day.status == 'not_completed',
                                                not_set: day.status == 'not_set', 
                                                warning: day.status == 'pending' || (moment(day.created_at).isSame(moment().format('YYYY-MM-DD')) && day.status == 'not_set'),
                                                blue: day.status == 'pending' && moment(day.created_at).isSame(moment().format('YYYY-MM-DD')),
                                                disabled: day.status == 'not_exist' }"
                                            ></div>
                                        </div>
                                        <template slot="popover">
                                            <div class="label" v-if="day && day.key_results_names != null"><icon-list class="icon-list" /> {{day.key_results_names}}</div>
                                            
                                            <div class="description" v-if="day.name">{{day.name}}</div>

                                            <div class="description" v-else-if="day.status == 'not_working'">{{ $t('timeline.promise-not-need') }}</div>
                                            <div class="description" v-else-if="day.status == 'free_day'">{{ $t('timeline.free_day') }}</div>

                                            <div class="description" v-else-if="day.status == 'no_key_result'">{{ $t('timeline.promise-without-key-result') }}</div>
                                            <div class="description" v-else>{{ $t('timeline.promise-not-set') }}</div>

                                            <div class="reason" v-if="day.why_not"><span>{{ $t('timeline.reason') }}:</span> {{ day.why_not }}</div>
                                        </template>
                                    </v-popover>
                                    <div class="date" v-if="$resize && $mq.above(600)" :class="{bigger: data.view == 'show_user'}">
                                        {{ moment(day.created_at).isSame(moment().format('YYYY-MM-DD')) ? $t('general.today_short') : (data.view == 'show_user' ? moment(day.created_at).format('ddd') : moment(day.created_at).format('MMM DD')) }}
                                    </div>
                                    <div class="date" v-else>{{ moment(day.created_at).format('ddd') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="hider-right"></div>
                        <div class="next-week" @click="timeline_offset <= default_timeline_offset ? '' : changeOffset(-1)" v-bind:class="{disabled: timeline_offset <= default_timeline_offset}">
                            <icon-arrow class="icon" />
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-else>
            <section class="subheader-page-section timeline">
                <div class="content-section">
                    <div class="header-table">
                        <div class="title placeholder-loader" style="height: 21px; max-width: 170px; width: 100%;"></div>
                        <div class="placeholder-loader" style="margin-left: auto; height: 37px; width: 100%; max-width: 140px;"></div>
                    </div>
                </div>
            </section>

            <section class="timeline-bar-section">
                <div class="content-section">
                    <div class="placeholder-loader" style="height: 36px; width: 100%; border-radius: 10px; margin-top: 75px;"></div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import IconList from '../../Icons/KeyResult'
import IconArrow from '../../Icons/Arrow'

export default {
    data() {
        return {
            loadedTimeline: '',
            timeline_offset: 4,
            default_timeline_offset: 4,
			dayWidth: 0,
            dayWidthPercent: 0,
            listDaysWidth: 0,
			weekNumbers: 6,
			data_timeline: {},
			user: {},
			notFound: false,
			notAllowed: false,
			firstLoadedTimeline: false,
            loadedCalculateDay: false
        }
    },
    components: {
        IconList,
        IconArrow
    },
    props: {
        data: Object
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    beforeDestroy() {  
        this.$root.$off('refresh_timeline');
        this.$root.$off('refreshWidthTimeline');
    },
    async mounted() {

        if(this.data.view == 'show_user'){
            this.user = this.data.user
            this.weekNumbers = 3
            this.timeline_offset = 1
            this.default_timeline_offset = 1
            this.getTimeline()
        } else {
            this.user = this.data.user
            this.getTimeline()

            if(this.$resize && this.$mq.between([1031,1300])){
                this.weekNumbers = 5
                this.timeline_offset = 3
                this.default_timeline_offset = 3
            }
    
            if(this.$resize && this.$mq.between([771,1030])){
                this.weekNumbers = 4
                this.timeline_offset = 2
                this.default_timeline_offset = 2
            }
    
            if(this.$resize && this.$mq.below(770)){
                this.weekNumbers = 3
                this.timeline_offset = 1
                this.default_timeline_offset = 1
            }
        }

        this.$root.$on('refresh_timeline', () => {
            this.getTimeline()
        });

        this.$root.$on('refreshWidthTimeline', () => {
            setTimeout(() => {
                this.handleResize();
            }, 220)
        })
    },
    methods: {
        async getTimeline(){
            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.user.slug}/timeline`, { params:{
                offset: this.timeline_offset,
                week_numbers: this.weekNumbers
            }})
            .then(({data}) => {
                this.data_timeline = data.data.array_of_promises_with_index
            }).catch(error => {
                var status = error.response.data.status
                this.notFound = status == 'not found'

                if(status == 'error' && error.response.data.message == 'Unauthorized'){
                    this.$auth.refresh()
                }
            }).then(() => {
                this.loadedTimeline = true

                this.$nextTick(() => {
                    this.calculateDayWidth()
                })
            })
        },
        changeOffset(val = false, reset = false){
            this.timeline_offset = reset == 'default' ? this.default_timeline_offset : this.timeline_offset + val

            this.$nextTick(() => {
                this.getTimeline()
            })
        },
        calculateDayWidth(){
            setTimeout(() => {
                var widthTotalDays = $('.timeline-bar .current-weeks').width();
                this.listDaysWidth = $('.timeline-bar').width() - 78;

                this.dayWidthPercent =  ( ( widthTotalDays / ((this.weekNumbers - 2) * 7) ) * 100 ) / widthTotalDays;
                this.dayWidth =  ( widthTotalDays / ((this.weekNumbers - 2) * 7) );

                this.$nextTick(() => {
                    this.loadedCalculateDay = true;
                    setTimeout(() => {
                        var widthMove = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 7
                        $('.timeline-bar .current-weeks .scroll-days .day').css('opacity', 1)
                        $('.timeline-bar .current-weeks .scroll-days').scrollLeft(widthMove)
                    }, 0);
        
                    this.firstLoadedTimeline = true
                })

            }, this.firstLoadedTimeline ? 0 : 200);

        },
        showModal(type, data = false, itemId = false){
            this.$root.$emit('open_modal', type, data, itemId);
        },
        handleResize(){
            if( this.data.view != 'show_user') {
                this.listDaysWidth = $('.timeline-bar-section').width() - 78;
                if(this.$mq.above(1301) && this.weekNumbers != 6){
                    this.weekNumbers = 6
                    this.timeline_offset = 4
                    this.default_timeline_offset = 4
                    this.getTimeline()
                }else if(this.$mq.between([1031,1300]) && this.weekNumbers != 5){
                    this.weekNumbers = 5
                    this.timeline_offset = 3
                    this.default_timeline_offset = 3
                    this.getTimeline()
                }else if(this.$mq.between([771,1030]) && this.weekNumbers != 4){
                    this.weekNumbers = 4
                    this.timeline_offset = 2
                    this.default_timeline_offset = 2
                    this.getTimeline()
                }else if(this.$mq.below(770) && this.weekNumbers != 3){
                    this.weekNumbers = 3
                    this.timeline_offset = 1
                    this.default_timeline_offset = 1
                    this.getTimeline()
                }else{
                    var widthTotalDays = $('.timeline-bar .current-weeks').width()
                    this.dayWidthPercent =  ( ( widthTotalDays / ((this.weekNumbers - 2) * 7) ) * 100 ) / widthTotalDays;
                    this.dayWidth =  ( widthTotalDays / ((this.weekNumbers - 2) * 7) );

                    var widthMove = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 7
                    $('.timeline-bar .current-weeks .scroll-days .day').css('opacity', 1)
                    $('.timeline-bar .current-weeks .scroll-days').scrollLeft(widthMove)
                }
            }
        },
    }
}
</script>
