<template>
	<div class="data-list list-tab-show-page">
		<template v-if="soft_objectives.is_active">
			<template v-if="loaded">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-name">
							<div class="text">{{ $t("general.title") }}</div>
						</div>
						<div v-if="$resize && $mq.above(600) && !$mq.between([1025, 1230])" class="column-filter column-status-obj">
							<div class="text">{{ $t("general.status") }}</div>
						</div>
						<div v-if="$resize && $mq.above(500)" class="column-filter column-deadline">
							<div class="text">{{ $t("show_user.deadline") }}</div>
						</div>
						<div v-if="$resize && $mq.above(650) && !$mq.between([1025, 1345])" class="column-filter column-last-update">
							<div class="text">{{ $t("show_user.last_value") }}</div>
						</div>
						<div v-if="$resize && $mq.above(900) && !$mq.between([1025, 1425])" class="column-filter column-progress-obj">
							<div class="text">{{ $t("show_user.progress") }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-if="entries.length">
					<div class="row-space-tbf row-list-item" :key="'objective' + entry.slug" v-for="entry in entries">
						<div class="space-left"><icon-objectives v-if="$resize && $mq.above(781)" /></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{ show: 0, hide: 0 }">
									<div class="name link" @click.stop="goItem(entry.objective.slug)">{{ entry.name }}</div>
									<template slot="popover">
										<div class="title">{{ entry.name }}</div>
										<div class="info-text">{{ entry.description }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(600) && !$mq.between([1025, 1230])" class="column-simple-text column-status-obj">
								<div class="text">{{ $t(`show_user.objectives_status.${entry.status}`) }}</div>
							</div>
							<div v-if="$resize && $mq.above(500)" class="column-simple-text column-deadline">
								<div class="text">{{ entry.end_date | moment("DD MMM YYYY") }}</div>
							</div>

							<div v-if="$resize && $mq.above(650) && !$mq.between([1025, 1345])" class="column-simple-text column-last-update">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
									<div class="text" v-if="displayKeyResultCurrentValue(entry) % 1 != 0">
										{{ displayKeyResultCurrentValue(entry) | numeral("0.00a") }}{{ optionsUnits[entry.unity] ? entry.unity : "" }}
										{{ !optionsUnits[entry.unity] ? entry.unity : optionsUnits[entry.unity] }}
									</div>
									<div class="text" v-else>
										{{ displayKeyResultCurrentValue(entry) | numeral("0a") }}{{ optionsUnits[entry.unity] ? entry.unity : "" }}
										{{ !optionsUnits[entry.unity] ? entry.unity : "(" + optionsUnits[entry.unity] + ")" }}
									</div>

									<template slot="popover">
										<div class="simple-text">
											{{
												$t("show_user.key_result_progress_type." + entry.progress_type, {
													to: entry.target % 1 != 0 ? $options.filters.numeral(entry.target, "0,0.00") : $options.filters.numeral(entry.target, "0,0"),
													unit: entry.unity,
													from: entry.start_value % 1 != 0 ? $options.filters.numeral(entry.start_value, "0,0.00") : $options.filters.numeral(entry.start_value, "0,0"),
												})
											}}
										</div>
									</template>
								</v-popover>
							</div>

							<div class="column-value-progress column-progress-obj" v-if="$resize && $mq.above(900) && !$mq.between([1025, 1425])">
								<v-popover offset="10" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
									<div class="top-data">
										<div class="current-value">
											<div class="value" v-if="moment(entry.start_date) >= moment()">0</div>
											<div class="value" v-else>{{ parseInt(entry.percent).toString() + "%" }}</div>
										</div>
										<div class="icon-info"><icon-info /></div>
									</div>
									<div class="progress-bar-tbf" v-bind:class="{ disabled: moment(entry.start_date) > moment() }">
										<div
											class="complete-progress-tbf"
											v-bind:class="{ pink: entry.status == 'overdue', green: entry.percent == 100 }"
											:style="'width: calc(2px + ' + (parseInt(entry.percent) < 1 ? '2px' : entry.percent + '%') + ');'"
										></div>
									</div>

									<template slot="popover">
										<div class="simple-text">
											<span v-if="entry.target % 1 != 0 || entry.start_value % 1 != 0"
												>{{
													$t("show_user.key_result_progress_type." + entry.progress_type, {
														to: $options.filters.numeral(entry.target, "0,0.00"),
														unit: entry.unity,
														from: $options.filters.numeral(entry.start_value, "0,0.00"),
													})
												}}.
											</span>
											<span v-else
												>{{
													$t("show_user.key_result_progress_type." + entry.progress_type, {
														to: $options.filters.numeral(entry.target, "0,0"),
														unit: entry.unity,
														from: $options.filters.numeral(entry.start_value, "0,0"),
													})
												}}.
											</span>

											<span v-if="displayKeyResultCurrentValue(entry) % 1 != 0"
												>{{ $t("show_user.key_result_progress_type.current_value") }}: {{ displayKeyResultCurrentValue(entry) | numeral("0,0.00") }} {{ entry.unity }}</span
											>
											<span v-else
												>{{ $t("show_user.key_result_progress_type.current_value") }}: {{ displayKeyResultCurrentValue(entry) | numeral("0,0") }} {{ entry.unity }}</span
											>
										</div>
									</template>
								</v-popover>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
				<template v-else>
					<div class="row-space-tbf row-list-item">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-empty-list">
								<div class="name"><img src="/build/images/no-results-found-inline.svg" />{{ $t("congratulations.empty-inline", { name: userName }) }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</template>
			<loader-items-list-2 v-else />
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
import NotModule from "@/components/General/NotModule";
import IconUser from "../../Icons/User";
import IconEdit from "../../Icons/EditDots";
import IconObjectives from "../../Icons/Objectives";
import IconEditMobile from "../../Icons/Edit";
import IconInfo from "../../Icons/Info";
import LoaderItemsList2 from "../../PagesLoaders/ItemsList2";
import StarRating from "vue-star-rating";

export default {
	components: {
		IconUser,
		IconEdit,
		IconEditMobile,
		IconObjectives,
		LoaderItemsList2,
		IconInfo,
		StarRating,
		NotModule,
	},
	data() {
		return {
			loaded: false,
			entries: [],
			optionsUnits: {
				"€": "Euro",
				$: "Dolar",
				"%": "Procent",
			},
		};
	},
	props: {
		userName: String,
		userSlug: String,
		dateRange: Object,
		status: String,
	},
	computed: {
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
	},
	watch: {
		$route(to, from) {
			if (this.soft_objectives.is_active) {
				this.getObjectivesEntries();
			}
		},
		status: function(newValue, oldValue) {
			if (this.soft_objectives.is_active) {
				this.getObjectivesEntries();
			}
		},
	},
	async mounted() {
		if (this.soft_objectives.is_active) {
			await this.getObjectivesEntries();
		}
	},
	methods: {
		async getObjectivesEntries() {
			let paramsRequest = {
				start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
				end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
			};

			if (this.status) {
				paramsRequest.status = this.status == "all" ? "active" : this.status;
			} else {
				paramsRequest.status = "active";
			}

			await axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.userSlug}/key-results`, { params: paramsRequest })
				.then(({ data }) => {
					this.entries = data.data;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
					}, 0);
				});
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		displayKeyResultCurrentValue(keyResult) {
			var text = "";
			var value = keyResult.last_update_value ? keyResult.last_update_value : keyResult.start_value;
			return value.includes(".00") ? parseInt(value).toString() : parseFloat(value).toString();
		},
		goItem(itemSlug) {
			window.open(`https://obiective.tbf.ro/obiectiv/${itemSlug}`, "_blank");
		},
	},
};
</script>
